import { IGatsbyImageData } from "gatsby-plugin-image"

export const getGatsbyImage = (
  imagePartName: string,
  allGatsbyImages: { gatsbyImageData: IGatsbyImageData }[] | undefined
) => {
  return allGatsbyImages?.find(
    image =>
      image?.gatsbyImageData?.images.sources &&
      image?.gatsbyImageData?.images?.sources[0].srcSet.includes(imagePartName)
  )?.gatsbyImageData
}
