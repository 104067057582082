import React from "react"

import { Heading, Body } from "@components/ui/Typography/Typography"
import "./ProjectItem.scss"
import Button from "@components/ui/Button/Button"
import Pills from "@components/ui/Pills/Pills"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"

export type ProjectItem = {
  reverseOrder?: boolean
  pills: string[]
  projectName: string
  description: string
  button?: { label?: string; link: string }
  fallbackSimplePicture: string
  gatsbyImage?: IGatsbyImageData
}

const ProjectItem = ({
  reverseOrder,
  pills,
  projectName,
  description,
  button,
  fallbackSimplePicture,
  gatsbyImage,
}: ProjectItem) => {
  return (
    <div
      className="our-projects-item"
      style={{ flexDirection: reverseOrder ? "row-reverse" : "row" }}
    >
      <div className="our-projects-item-infos">
        <div className="our-projects-item-infos-pills-container">
          {pills.map((pillLabel, index) => (
            <Pills
              key={index}
              backgroundColor={index > 0 ? "#F3F4F6" : undefined}
              labelColor={index > 0 ? "#4B5563" : undefined}
            >
              {pillLabel}
            </Pills>
          ))}
        </div>
        <Heading>{projectName}</Heading>
        <Body>{description}</Body>
        <Button variant="tertiary" to={button?.link ?? "#"}>
          {button?.label ?? "Discover website"}
        </Button>
      </div>
      <div className="our-projects-item-picture">
        {!gatsbyImage ? (
          <img src={fallbackSimplePicture} alt={projectName} />
        ) : (
          <GatsbyImage
            image={gatsbyImage}
            alt={projectName}
            objectFit="contain"
            objectPosition="center"
            className="project-item-image"
          />
        )}
      </div>
    </div>
  )
}

export default ProjectItem
