import * as React from "react"

import "./articles.scss"
import SEO from "@components/SEO/SEO"
import Layout from "@components/layout/layout"
import ProjectsHero from "@landingsParts/Projects/ProjectsHero"
import ProjectsList from "@landingsParts/Projects/ProjectsList/ProjectsList"
import { graphql } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

// markup
const ProjectsPage = ({ data }: ProjectsPageQuery) => {
  const projectsImages = data.allImages.nodes

  return (
    <main>
      <Layout>
        <SEO
          title="Kezios - Our projects"
          description={`Some projects where the Kezios team has participated.`}
        />

        <ProjectsHero />
        <ProjectsList projectImages={projectsImages} />
      </Layout>
    </main>
  )
}

interface ProjectsPageQuery {
  data: {
    allImages: {
      nodes: { gatsbyImageData: IGatsbyImageData }[]
    }
  }
}

export const query = graphql`
  query ProjectsPageQuery {
    allImages: allImageSharp {
      nodes {
        gatsbyImageData
      }
    }
  }
`
export default ProjectsPage
