import React from "react"

import CenteredContent from "@components/CenteredContent/CenteredContent"
import { Body, Heading } from "@components/ui/Typography/Typography"

import "./ProjectsHero.scss"
import chefclub from "./assets/chefclub.png"
import clickandcare from "./assets/clickandcare.png"
import toolpad from "./assets/toolpad.png"
import placemeet from "./assets/placemeet.png"

const ProjectsHero = () => {
  return (
    <section className="projects-hero-container">
      <CenteredContent>
        <Heading>Our projects</Heading>
        <Body>
          Discover the latest projects on which our team was able to intervene
          to strengthen the development team.
        </Body>
      </CenteredContent>
      <div className="projects-hero-curve-container">
        <div className="projects-hero-curve" />
        <div className="projects-hero-img-container">
          <img
            src={clickandcare}
            alt="clickandcare"
            style={{ left: "15%", bottom: "-23px", width: "35.55%" }}
          />
          <img
            src={toolpad}
            alt="toolpad"
            style={{ left: "40%", bottom: "-23px", width: "42.64%" }}
          />
          <img
            src={chefclub}
            alt="chefclub"
            style={{ left: "27%", bottom: "-23px", width: "37.08%" }}
          />
          <img
            src={placemeet}
            alt="placemeet"
            style={{ left: "52%", bottom: "-23px", width: "19.31%" }}
          />
        </div>
      </div>
    </section>
  )
}

export default ProjectsHero
