import React from "react"

import "./ProjectsList.scss"
import ProjectItem from "@components/ProjectItem/ProjectItem"

import chefclubPreviewImg from "./assets/chefclub-preview.png"
import clickandcarePreviewImg from "./assets/clickandcare-preview.png"
import placemeetPreviewImg from "./assets/placemeet-preview.png"
import curecallPreviewImg from "./assets/curecall-preview.png"
import ateliernumPreviewImg from "./assets/ateliernum-preview.png"
import toolpadPreviewImg from "./assets/toolpad-preview.png"
import visiteplusPreviewImg from "./assets/visiteplus-preview.png"
import autocutPreviewImg from "./assets/autocut-preview.png"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { getGatsbyImage } from "@utils/images"

interface PropTypes {
  projectImages?: { gatsbyImageData: IGatsbyImageData }[]
}

const ProjectsList = ({ projectImages }: PropTypes) => {
  return (
    <section className="our-projects" id="our-projects">
      <div className="our-projects-list">
        <ProjectItem
          pills={["Gatsby", "Performance", "SEO", "Typescript"]}
          description={
            "A digital cooking brand that has rapidly grown in Europe, the United States, Latin America and China thanks to the free distribution of high quality content."
          }
          projectName={"Chefclub"}
          button={{ link: "https://www.chefclub.tv" }}
          fallbackSimplePicture={chefclubPreviewImg}
          gatsbyImage={getGatsbyImage("chefclub", projectImages)}
        />
        <ProjectItem
          reverseOrder
          pills={["Nest.js", "React Native", "TypeScript", "TDD"]}
          description={
            "Click & Care is a startup that has raised funds, developing a web & mobile support platform for all actors wishing to set up a home help service, relieving them of recruitment and administrative costs."
          }
          projectName={"Click & Care"}
          button={{ link: "https://clickandcare.fr" }}
          fallbackSimplePicture={clickandcarePreviewImg}
          gatsbyImage={getGatsbyImage("clickandcare", projectImages)}
        />
        <ProjectItem
          pills={["Firebase", "PWA", "React.js", "TypeScript"]}
          description={
            "It's a space dedicated to your online business events. A human experience, inspired by the real world."
          }
          projectName={"Placemeet"}
          button={{ link: "https://placemeet.fr" }}
          fallbackSimplePicture={placemeetPreviewImg}
          gatsbyImage={getGatsbyImage("placemeet", projectImages)}
        />
        <ProjectItem
          reverseOrder
          pills={["React", "Extension", "TypeScript", "SASS"]}
          description={
            "Autocut is an Adobe Premiere Pro extension which detect and cut all blank space in a video to speed up the editing workflow"
          }
          projectName={"AutoCut"}
          button={{ link: "https://www.autocut.fr" }}
          fallbackSimplePicture={autocutPreviewImg}
          gatsbyImage={getGatsbyImage("autocut", projectImages)}
        />
        <ProjectItem
          pills={["React", "Gatsby", "Cypress", "TypeScript"]}
          description={
            "Curecall is a saas that simplifies the management of patients and saves time by automating their follow-up via SMS"
          }
          projectName={"Curecall"}
          button={{ link: "https://www.curecall.fr" }}
          fallbackSimplePicture={curecallPreviewImg}
          gatsbyImage={getGatsbyImage("curecall", projectImages)}
        />
        <ProjectItem
          reverseOrder
          pills={["React.js", "Inclusion numérique", "Redux", "SASS"]}
          description={
            "Atelier num' is a platform to train users during digital workshops, with exercises adapted to their level"
          }
          projectName={"AtelierNum'"}
          button={{ link: "https://atelier.fr" }}
          fallbackSimplePicture={ateliernumPreviewImg}
          gatsbyImage={getGatsbyImage("ateliernum", projectImages)}
        />
        <ProjectItem
          pills={["Hardware", "Inclusion numérique", ".net", "Electron"]}
          description={
            "The ToolPad Neo is the right support for digital mediators in accompanying users. Step by step, with a pedagogical approach, users learn how to carry out the required administrative procedures. This allows the digital mediator to devote his time to more complex procedures."
          }
          projectName={"ToolPad"}
          button={{ link: "https://www.toolpad.fr" }}
          fallbackSimplePicture={toolpadPreviewImg}
          gatsbyImage={getGatsbyImage("toolpad", projectImages)}
        />
        <ProjectItem
          reverseOrder
          pills={["Microservices", "Cross-platform", "Flutter", "Firebase"]}
          description={
            "Visite+ is a user friendly saas and an app to generate and read QR codes linked to ressources that parc or museum visitors can scan to know more about what they looking at."
          }
          projectName={"Visite+"}
          button={{ link: "https://www.visiteplus.fr" }}
          fallbackSimplePicture={visiteplusPreviewImg}
          gatsbyImage={getGatsbyImage("visiteplus", projectImages)}
        />
      </div>
    </section>
  )
}

export default ProjectsList
